@tailwind base;
@tailwind components;
@tailwind utilities;

@-ms-viewport{
  width: device-width;
}

@layer base {
  html,
  body,
  main {
    margin: 0rem;
    padding: 0rem;
    font-family: "Space Grotesk", sans-serif;
    @apply bg-primary-black;
  }

  html {
    height: 100vh;
    width: 100vw;
    scroll-behavior: smooth;
   
  }

  h1,
  h2,
  h3 {
    font-weight: bold;
  }

  h1 {
    @apply text-h1;
    @apply tracking-h1;
  }
  h2 {
    @apply text-h2;
    @apply tracking-h2;
  }
  h3 {
    @apply text-h3;
  }
  p {
    @apply text-p;
    font-weight: 500;
  }

  a,
  button {
    cursor: pointer;
    z-index: 50
  }
}

@layer components {
  .underline {
    text-decoration: underline;
    @apply decoration-primary-neonGreen;
  }

  .logo {
    margin: 1rem 1rem; 
    height: 3rem;
    width: 3rem;
  }
}
